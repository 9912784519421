
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  DynamicField,
  FieldConfigUnion,
  FieldUnion,
  RepeaterField,
  RepeaterIncomingValue,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";
import {
  convertRequestErrorToMap,
  getNameWithDepth,
  navigateBack,
  urlTranslit,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import {
  FormField,
  FormFieldFileInput,
  FormFieldSelect,
  FormFieldUrlAliasInput,
  FormFooter,
  OptionType,
  SeoChangeEvent,
  SeoFieldGroup,
  type TabType,
  TagerFormSubmitEvent,
  useDataTable,
} from "@tager/admin-ui";

import {
  createBrand,
  getBrand,
  getBrandsCatalog,
  updateBrand,
} from "@/services/brands";
import { getBrandsListUrl, getBrandsUpdateUrl } from "@/utils/paths";
import { ServiceInterface } from "@/typings/model";
import { getServicesList } from "@/services/services";

import {
  convertPageFormValuesToCreationOrUpdatePayload,
  FormValues,
  getBrandFormValues,
} from "./BrandsForm.helpers";

const fieldBlogTagsConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Теги блога",
  meta: {
    view: "TABLE",
  },
  fields: [
    {
      name: "tag",
      type: "STRING",
      label: "Tag",
      meta: {},
    },
  ],
};

const getPopularServicesConfig = (
  services: Array<ServiceInterface>
): FieldConfigUnion => {
  return {
    name: "",
    type: "REPEATER",
    label: "Популярные сервисы",
    meta: {
      view: "TABLE",
    },
    fields: [
      {
        name: "service",
        type: "SELECT",
        label: "Сервис",
        meta: {
          options: services.map((item) => {
            return {
              value: item.id,
              label: getNameWithDepth(item.name, item.depth),
            };
          }),
        },
      },
    ],
  };
};

const fieldStatsDataConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Статистика - Цифры",
  meta: {
    view: "TABLE",
  },
  fields: [
    {
      name: "icon",
      type: "IMAGE",
      label: "Иконка (50 x 50)",
      meta: {
        scenario: "service-brand-stats-icon",
      },
    },
    {
      name: "text",
      type: "STRING",
      label: "Значение",
      meta: {},
    },
    {
      name: "color",
      type: "SELECT",
      label: "Цвет",
      meta: {
        options: [
          {
            value: "PRIMARY",
            label: "Основной",
          },
          {
            value: "BLACK",
            label: "Черный",
          },
        ],
      },
    },
  ],
};

const statsTitleColorOptions = [
  {
    value: "PRIMARY",
    label: "Основной",
  },
  {
    value: "BLACK",
    label: "Черный",
  },
];

export default defineComponent({
  name: "BrandsForm",
  components: {
    FormFieldSelect,
    FormFieldUrlAliasInput,
    Page,
    FormField,
    FormFooter,
    FormFieldFileInput,
    DynamicField,
    SeoFieldGroup,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const urlAliasChanged = ref(false);

    const id = computed(() => route.params.id as string | undefined);

    const isCreation = computed(() => id.value === "create");

    const [fetchBrand, { data: brand, loading: isBrandLoading }] = useResource({
      fetchResource: () => {
        if (id.value && !isCreation.value) {
          return getBrand(id.value);
        }

        return Promise.resolve({ data: null });
      },
      initialValue: null,
      resourceName: "Brand",
    });

    const [fetchCatalog, { data: catalog, loading: isCatalogLoading }] =
      useResource({
        fetchResource: getBrandsCatalog,
        initialValue: null,
        resourceName: "Brands Catalog",
      });

    const {
      fetchEntityList: fetchServicesList,
      isLoading: isServicesListLoading,
      rowData: servicesListData,
    } = useDataTable<ServiceInterface>({
      fetchEntityList: (params) =>
        getServicesList({
          pageNumber: 1,
          pageSize: 100000,
        }),
      initialValue: [],
      resourceName: "Список сервисов",
    });

    const loadData = () => {
      fetchBrand();
      fetchCatalog();
      fetchServicesList();
    };

    const catalogBrandOptions = computed<Array<OptionType<number>>>(
      () =>
        catalog.value?.map((item) => ({
          value: item.id,
          label: item.name,
        })) || []
    );

    onMounted(loadData);
    watch(id, loadData);

    const errors = ref<Record<string, string>>({});
    const values = ref<FormValues>(
      getBrandFormValues(brand.value, catalogBrandOptions.value)
    );

    watch(catalogBrandOptions, updateFormValues);

    const isSubmitting = ref<boolean>(false);

    function updateFormValues() {
      values.value = getBrandFormValues(brand.value, catalogBrandOptions.value);
    }

    onMounted(() => {
      updateFormValues();
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const createOrUpdatePayload =
        convertPageFormValuesToCreationOrUpdatePayload(
          values.value,
          blogTags.value as RepeaterField,
          popularServices.value as RepeaterField,
          statsData.value as RepeaterField
        );

      const requestPromise = isCreation.value
        ? createBrand(createOrUpdatePayload)
        : updateBrand(id.value || "", createOrUpdatePayload);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(getBrandsUpdateUrl(response.data.id));
          }

          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getBrandsListUrl());
          }

          if (event.type === "create_create-another") {
            values.value = getBrandFormValues(null, catalogBrandOptions.value);
          }

          toast.show({
            variant: "success",
            title: "Бренды",
            body: isCreation.value
              ? "Бренд успешно создан"
              : "Бренд успешно обновлен",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Бренды",
            body: isCreation.value
              ? "Ошибка добавления бренда"
              : "Ошибка изменения бренда",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const fieldPopularServicesConfig = computed<FieldConfigUnion>(() => {
      return getPopularServicesConfig(servicesListData.value);
    });

    const popularServices = ref<FieldUnion>(
      universalFieldUtils.createFormField(
        fieldPopularServicesConfig.value,
        null
      )
    );

    watch(fieldPopularServicesConfig, () => {
      popularServices.value = universalFieldUtils.createFormField(
        fieldPopularServicesConfig.value,
        null
      );
    });

    function updatePopularServices() {
      const services = servicesListData.value;

      const incomingFieldList: RepeaterIncomingValue = brand.value
        ?.popularServices
        ? brand.value?.popularServices.map((id) => {
            const serviceModel = services.find((item) => item.id === id);
            return [
              {
                name: "service",
                value: serviceModel?.id || null,
              },
            ];
          })
        : [];

      if (brand.value) {
        popularServices.value = universalFieldUtils.createFormField(
          fieldPopularServicesConfig.value,
          incomingFieldList
        );
      }
    }

    const blogTags = ref<FieldUnion>(
      universalFieldUtils.createFormField(fieldBlogTagsConfig, null)
    );

    function updateBlogTags() {
      const incomingFieldList: RepeaterIncomingValue = brand.value?.blogTags
        ? brand.value?.blogTags.map((item) => {
            return [
              {
                name: "tag",
                value: item,
              },
            ];
          })
        : [];

      if (brand.value) {
        blogTags.value = universalFieldUtils.createFormField(
          fieldBlogTagsConfig,
          incomingFieldList
        );
      }
    }

    const statsData = ref<FieldUnion>(
      universalFieldUtils.createFormField(fieldStatsDataConfig, null)
    );

    function updateStatsData() {
      const incomingFieldList: RepeaterIncomingValue = brand.value?.statsData
        ? brand.value?.statsData.map((item) => {
            return [
              {
                name: "icon",
                value: item.icon,
              },
              {
                name: "text",
                value: item.text,
              },
              {
                name: "color",
                value: item.color,
              },
            ];
          })
        : [];

      if (brand.value) {
        statsData.value = universalFieldUtils.createFormField(
          fieldStatsDataConfig,
          incomingFieldList
        );
      }
    }

    watch([brand], () => {
      updateFormValues();
      updateBlogTags();
      updateStatsData();
      updatePopularServices();
    });

    watch([servicesListData], () => {
      updatePopularServices();
    });

    const isLoading = computed<boolean>(
      () =>
        isBrandLoading.value ||
        isServicesListLoading.value ||
        isCatalogLoading.value
    );

    function handleSeoFieldGroupChange({
      title,
      description,
      image,
    }: SeoChangeEvent) {
      values.value.pageTitle = title;
      values.value.pageDescription = description;
      values.value.openGraphImage = image;
    }

    const tabList = computed<Array<TabType>>(() => {
      return [
        { id: "common", label: "Основное" },
        { id: "template", label: "Содержание" },
        { id: "seo", label: "SEO" },
      ];
    });

    const selectedTabId = ref<string>(tabList.value[0].id);

    let websiteOrigin: string =
      process.env.VUE_APP_WEBSITE_URL || window.location.origin;
    if (websiteOrigin.substr(-1) === "/") {
      websiteOrigin = websiteOrigin.substr(0, websiteOrigin.length - 1);
    }

    function handleTitleChange(value: string) {
      if (isCreation.value) {
        if (urlAliasChanged.value === false) {
          values.value.urlAlias = urlTranslit(value);
        }
      }
    }

    function handleAliasChange() {
      urlAliasChanged.value = true;
    }

    return {
      isLoading,
      values,
      errors,
      brand,
      isCreation,
      submitForm,
      backButtonUrl: getBrandsListUrl(),
      isSubmitting,

      blogTags,
      statsData,
      popularServices,

      handleSeoFieldGroupChange,
      tabList,
      selectedTabId,
      websiteOrigin,
      handleTitleChange,
      handleAliasChange,
      statsTitleColorOptions,
      catalogBrandOptions,
    };
  },
});
