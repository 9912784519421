import { createId, Nullable } from "@tager/admin-services";
import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";
import { RepeaterField } from "@tager/admin-dynamic-field/dist/typings/model";

import { BrandCreateOrUpdatePayload } from "@/services/brands";
import { BrandFullInterface } from "@/typings/model";

export type FormValues = {
  catalog: Nullable<OptionType<number>>;
  name: string;
  nameRu: string;
  urlAlias: string;
  phone: string;
  logo: Nullable<SingleFileInputValueType>;
  logoDark: Nullable<SingleFileInputValueType>;
  blogTags: Array<{
    tag: string;
  }>;
  bannerTitle: string;
  bannerText: string;
  bannerImage: Nullable<SingleFileInputValueType>;
  bannerImageMobile: Nullable<SingleFileInputValueType>;
  statsTitle: string;
  statsTitleColor: Nullable<OptionType<string>>;
  statsImage: Nullable<SingleFileInputValueType>;
  statsImageMobile: Nullable<SingleFileInputValueType>;
  statsData: Array<{
    label: string;
    value: string;
  }>;
  popularServices: Array<{
    service: number;
  }>;
  pageTitle: Nullable<string>;
  pageDescription: Nullable<string>;
  openGraphImage: Nullable<SingleFileInputValueType>;
};

const statsTitleColorOptions: Array<OptionType> = [
  {
    value: "PRIMARY",
    label: "Основной",
  },
  {
    value: "BLACK",
    label: "Черный",
  },
];

export function getBrandFormValues(
  brand: Nullable<BrandFullInterface>,
  catalogBrandOptions: Array<OptionType<number>>
): FormValues {
  if (!brand) {
    return {
      catalog: null,
      name: "",
      nameRu: "",
      urlAlias: "",
      phone: "",
      logo: null,
      logoDark: null,
      blogTags: [],
      popularServices: [],
      bannerTitle: "",
      bannerText: "",
      bannerImage: null,
      bannerImageMobile: null,
      statsTitle: "",
      statsTitleColor: null,
      statsImage: null,
      statsImageMobile: null,
      statsData: [],
      pageTitle: "",
      pageDescription: "",
      openGraphImage: null,
    };
  }

  return {
    catalog:
      catalogBrandOptions.find((item) => item.value === brand.catalogId) ||
      null,
    name: brand.name || "",
    nameRu: brand.nameRu || "",
    urlAlias: brand.urlAlias,
    blogTags: [],
    logo: brand.logo ? { id: createId(), file: brand.logo } : null,
    logoDark: brand.logoDark ? { id: createId(), file: brand.logoDark } : null,
    popularServices: [],
    bannerTitle: brand.bannerTitle,
    phone: brand.phone,
    bannerText: brand.bannerText,
    bannerImage: brand.bannerImage
      ? { id: createId(), file: brand.bannerImage }
      : null,
    bannerImageMobile: brand.bannerImageMobile
      ? { id: createId(), file: brand.bannerImageMobile }
      : null,
    statsTitle: brand.statsTitle,
    statsTitleColor: brand.statsTitleColor
      ? statsTitleColorOptions.find(
          (item) => item.value === brand.statsTitleColor
        ) || null
      : null,
    statsImage: brand.statsImage
      ? { id: createId(), file: brand.statsImage }
      : null,
    statsImageMobile: brand.statsImageMobile
      ? { id: createId(), file: brand.statsImageMobile }
      : null,
    statsData: [],

    pageTitle: brand.pageTitle || "",
    pageDescription: brand.pageDescription || "",
    openGraphImage: brand.openGraphImage
      ? { id: createId(), file: brand.openGraphImage }
      : null,
  };
}

export function convertPageFormValuesToCreationOrUpdatePayload(
  values: FormValues,
  blogTags: RepeaterField,
  popularServices: RepeaterField,
  statsData: RepeaterField
): BrandCreateOrUpdatePayload {
  const statsDataValue = statsData.value
    ? statsData.value.map((item) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const color = item.value[2].value?.value;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const icon = item.value[0].value?.file?.id || null;

        return {
          icon,
          text: String(item.value[1].value),
          color,
        };
      })
    : [];

  const blogTagsValue = blogTags.value
    ? blogTags.value.map((item) => String(item.value[0].value))
    : [];

  const popularServicesValue = popularServices.value
    ? popularServices.value.map((item) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return item.value[0].value ? +item.value[0].value?.value : 0;
      })
    : [];

  return {
    catalog: values.catalog?.value || null,
    name: values.name,
    nameRu: values.nameRu,
    urlAlias: values.urlAlias,
    logo: values.logo?.file.id ?? null,
    logoDark: values.logoDark?.file.id ?? null,
    blogTags: blogTagsValue,
    popularServices: popularServicesValue,
    phone: values.phone,
    bannerTitle: values.bannerTitle,
    bannerText: values.bannerText,
    bannerImage: values.bannerImage?.file.id ?? null,
    bannerImageMobile: values.bannerImageMobile?.file.id ?? null,
    statsTitle: values.statsTitle,
    statsTitleColor: values.statsTitleColor?.value || null,
    statsImage: values.statsImage?.file.id ?? null,
    statsImageMobile: values.statsImageMobile?.file.id ?? null,
    statsData: statsDataValue,
    pageTitle: values.pageTitle || "",
    pageDescription: values.pageDescription || "",
    openGraphImage: values.openGraphImage?.file.id ?? null,
  };
}
