import {
  FileType,
  Nullable,
  request,
  ResponseBody,
} from "@tager/admin-services";

import { BrandFullInterface, BrandInterface } from "@/typings/model";

export interface CountData {
  count: number;
}

export function getBrandsCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/brands/count" });
}

export function getBrandsCatalog(): Promise<
  ResponseBody<Array<{ id: number; name: string }>>
> {
  return request.get({ path: "/admin/brands/catalog" });
}

export function getBrandsList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<BrandInterface>>> {
  return request.get({ path: "/admin/brands", params });
}

export function getBrand(
  id: string | number
): Promise<ResponseBody<BrandFullInterface>> {
  return request.get({ path: `/admin/brands/${id}` });
}

export interface BrandCreateOrUpdatePayload {
  catalog: number | null;
  name: string;
  nameRu: string;
  urlAlias: string;
  blogTags: string[];
  popularServices: number[];
  phone: string;
  logo: Nullable<FileType["id"]>;
  logoDark: Nullable<FileType["id"]>;
  bannerTitle: string;
  bannerImage: Nullable<FileType["id"]>;
  bannerImageMobile: Nullable<FileType["id"]>;
  bannerText: string;
  statsTitle: string;
  statsTitleColor: Nullable<string>;
  statsImage: Nullable<FileType["id"]>;
  statsImageMobile: Nullable<FileType["id"]>;
  statsData: Array<{
    icon: string;
    text: string;
    color: string;
  }>;
  pageTitle: string;
  pageDescription: string;
  openGraphImage: Nullable<FileType["id"]>;
}

export function createBrand(
  payload: BrandCreateOrUpdatePayload
): Promise<ResponseBody<BrandFullInterface>> {
  return request.post({
    path: `/admin/brands`,
    body: payload,
  });
}

export function updateBrand(
  id: number | string,
  payload: BrandCreateOrUpdatePayload
): Promise<ResponseBody<BrandFullInterface>> {
  return request.put({
    path: `/admin/brands/${id}`,
    body: payload,
  });
}

export function deleteBrand(
  id: number | string
): Promise<{ success: boolean }> {
  return request.delete({ path: `/admin/brands/${id}` });
}

export function cloneBrand(id: number | string): Promise<{ data: any }> {
  return request.post({ path: `/admin/brands/${id}/clone` });
}

export function moveBrand(
  id: number | string,
  direction: "up" | "down"
): Promise<{ success: boolean }> {
  return request.post({ path: `/admin/brands/${id}/move/${direction}` });
}
